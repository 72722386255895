import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1079.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 5400 l0 -5400 5395 0 5395 0 0 5400 0 5400 -5395 0 -5395 0 0
-5400z m4605 3075 c93 -25 136 -48 203 -110 209 -193 182 -524 -54 -685 -93
-63 -132 -70 -379 -70 l-225 0 0 280 0 280 95 0 95 0 0 -185 0 -185 119 0
c156 0 203 20 262 111 45 71 52 164 17 241 -15 33 -41 66 -66 86 -70 56 -103
62 -323 62 l-199 0 0 88 c0 49 3 92 7 95 3 4 92 7 198 7 139 0 207 -4 250 -15z
m1163 -82 l3 -93 -396 0 -395 0 0 88 c0 49 3 92 7 95 4 4 180 6 392 5 l386 -3
3 -92z m320 -65 l3 -158 199 0 199 0 3 158 3 157 90 0 90 0 3 -438 2 -438 -92
3 -93 3 -3 183 -2 182 -200 0 -200 0 0 -185 0 -186 -92 3 -93 3 -3 425 c-1
234 0 431 3 438 3 9 28 12 92 10 l88 -3 3 -157z m1854 -178 c17 -21 18 -131
18 -2371 0 -2011 -2 -2355 -14 -2384 -12 -27 -23 -36 -58 -45 -23 -7 -61 -21
-83 -33 -22 -11 -60 -26 -85 -33 -25 -7 -67 -21 -95 -33 -27 -11 -122 -46
-210 -77 -88 -31 -207 -74 -265 -94 -58 -21 -134 -49 -170 -63 -74 -29 -130
-39 -147 -29 -39 24 -38 -26 -41 2527 -1 1419 2 2495 7 2544 6 54 15 89 25
100 15 14 71 17 519 21 276 3 520 2 542 -2 22 -3 47 -15 57 -28z m-3975 10
c13 -11 24 -38 28 -62 3 -24 7 -1166 8 -2538 2 -2332 1 -2497 -15 -2533 -30
-66 -15 -69 -428 83 -298 110 -377 139 -486 174 -39 13 -82 30 -95 39 -13 9
-41 19 -61 22 -76 14 -72 -13 -79 549 -4 275 -5 1293 -3 2261 3 968 5 1810 4
1872 0 170 -63 153 575 153 516 0 532 -1 552 -20z m1493 -270 l0 -280 -89 0
-88 0 -7 46 c-8 60 -8 477 0 498 5 13 23 16 95 16 l89 0 0 -280z m-187 -378
c61 -26 57 149 55 -2528 l-3 -2446 -22 -18 c-18 -14 -30 -16 -55 -10 -34 8
-262 88 -513 179 -77 28 -227 82 -333 119 -209 75 -234 92 -246 161 -11 71 -7
4456 5 4490 13 36 37 52 94 62 68 13 986 4 1018 -9z m1268 8 c34 -7 63 -20 79
-35 l25 -25 0 -2253 0 -2252 -23 -33 c-28 -38 -51 -51 -156 -86 -43 -15 -131
-46 -195 -70 -64 -24 -163 -61 -221 -82 -58 -20 -148 -53 -200 -71 -197 -71
-323 -110 -340 -106 -11 3 -23 18 -29 35 -7 19 -10 698 -11 2076 0 1126 -3
2178 -6 2337 -9 367 -1 496 31 533 21 24 32 28 107 33 150 10 883 9 939 -1z
m-2640 -5779 c13 -1 17 -15 18 -76 l2 -75 -111 0 -110 0 0 -285 0 -285 -95 0
-95 0 0 285 0 284 -107 3 -108 3 -1 68 c0 44 4 70 12 75 9 5 462 7 595 3z
m1264 -20 c33 -12 71 -30 84 -40 l23 -18 -32 -64 c-18 -35 -34 -66 -36 -68 -2
-2 -30 8 -61 22 -79 35 -171 53 -208 40 -23 -7 -31 -16 -33 -39 -4 -43 18 -58
142 -99 97 -32 148 -57 182 -90 43 -41 58 -152 30 -219 -35 -83 -171 -144
-300 -133 -130 11 -296 81 -296 125 0 9 15 43 33 74 l32 57 30 -20 c95 -64
202 -96 264 -79 37 10 61 33 61 58 0 27 -46 54 -147 87 -60 19 -128 47 -151
63 -98 63 -111 214 -25 293 34 33 87 60 133 70 61 14 210 3 275 -20z m385
-218 c0 -122 4 -233 10 -248 21 -56 67 -85 135 -85 60 0 100 24 125 75 18 37
20 63 20 269 l0 227 93 -3 92 -3 3 -185 c5 -295 -13 -376 -100 -460 -110 -106
-350 -106 -470 0 -26 23 -55 61 -69 92 -23 51 -24 64 -27 305 l-3 252 88 3
c51 2 91 -1 95 -7 4 -5 8 -110 8 -232z m2010 -124 l0 -359 -92 0 -93 0 0 137
0 138 -145 0 -145 0 -3 -137 -3 -138 -94 0 -95 0 0 360 0 360 95 0 94 0 3
-146 3 -145 143 -2 c131 -2 143 -1 148 17 4 10 5 75 3 145 l-4 126 75 5 c123
8 110 50 110 -361z m-5249 345 c199 -59 304 -261 238 -457 -32 -94 -87 -160
-169 -202 -71 -37 -130 -45 -322 -45 l-188 0 0 360 0 360 193 0 c149 0 206 -4
248 -16z m773 -16 c7 -18 57 -136 110 -263 53 -126 115 -271 136 -321 22 -50
40 -94 40 -98 0 -3 -44 -6 -98 -6 l-98 0 -25 63 -24 62 -159 0 -158 0 -25 -62
-25 -63 -94 0 c-52 0 -94 2 -94 4 0 2 46 109 101 238 56 128 112 258 124 288
12 30 36 85 54 123 l31 67 95 0 95 0 14 -32z m1277 -15 c12 -27 45 -104 74
-173 29 -69 84 -199 122 -290 100 -237 102 -210 -14 -210 l-99 0 -23 65 -23
65 -158 0 -158 0 -25 -62 -25 -63 -96 -3 c-53 -1 -96 -1 -96 0 0 2 21 50 46
108 25 58 91 211 146 340 55 129 104 243 109 253 8 14 24 17 105 17 l95 0 20
-47z m2253 36 c94 -26 146 -86 146 -168 0 -64 -19 -103 -67 -135 l-36 -25 24
-9 c37 -15 88 -72 99 -111 23 -82 3 -150 -57 -200 -65 -54 -113 -62 -354 -59
l-214 3 -3 345 c-1 190 0 351 3 357 6 17 401 18 459 2z m1266 -216 c0 -208 2
-230 20 -269 49 -100 214 -99 256 1 11 27 14 87 14 265 l0 230 95 0 95 0 0
-220 c0 -235 -8 -301 -46 -367 -54 -94 -179 -151 -311 -141 -165 13 -262 86
-298 225 -11 42 -15 115 -15 280 l0 223 95 0 95 0 0 -227z m1074 213 c67 -20
111 -58 132 -112 15 -40 16 -52 5 -92 -13 -51 -31 -76 -74 -102 l-28 -18 42
-23 c57 -32 89 -87 89 -157 0 -63 -16 -98 -64 -140 -60 -53 -113 -62 -353 -62
l-213 0 0 360 0 360 208 0 c151 0 222 -4 256 -14z"/>
<path d="M2060 1383 l0 -213 87 0 c66 0 96 5 127 20 104 50 138 219 64 322
-40 57 -75 72 -180 78 l-98 5 0 -212z"/>
<path d="M2923 1438 c-65 -165 -70 -148 41 -148 75 0 96 3 96 14 0 20 -71 209
-85 224 -9 10 -20 -10 -52 -90z"/>
<path d="M4192 1433 c-23 -60 -44 -116 -48 -125 -6 -16 3 -18 88 -18 52 0 97
2 100 5 6 7 -83 245 -92 245 -4 0 -26 -48 -48 -107z"/>
<path d="M6340 1525 l0 -75 75 0 c122 0 169 33 151 103 -8 34 -46 47 -141 47
l-85 0 0 -75z"/>
<path d="M6340 1240 l0 -83 105 5 c122 5 145 18 145 78 0 61 -24 74 -146 78
l-104 4 0 -82z"/>
<path d="M8672 1528 l3 -73 92 0 c74 0 95 4 111 18 28 25 32 50 15 85 -18 37
-35 42 -141 42 l-83 0 3 -72z"/>
<path d="M8674 1306 c-3 -7 -4 -42 -2 -77 l3 -64 76 -3 c92 -3 148 13 169 51
13 24 13 30 0 55 -21 36 -68 51 -163 51 -56 1 -79 -3 -83 -13z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
